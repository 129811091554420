<template>
    <BaseModal
        v-slot="{ close }"
        v-model="isModalOpen"
        :size="isPasswordResetType ? 'md' : 'lg'"
        no-padding
    >

        <CustomerAuthForms
            v-model="type"
            :close="close"
            color="primary"
            @submitted="handleAuthFormSubmit"
        >
            <template #heading>
                <div class="flex w-full items-center justify-between">
                    <h2 class="sim-subtitle sim-font-medium">
                        {{ modalTexts.heading }}
                    </h2>

                    <UiButtonClose :class="{'md:!hidden': !isPasswordResetType}" @click="close" />
                </div>
            </template>
        </CustomerAuthForms>

    </BaseModal>
</template>

<script lang="ts" setup>
import { CustomerAuthFormType } from '../../../../types/components'


const type = defineModel<CustomerAuthFormType>('type', {
    default: CustomerAuthFormType.LOGIN,
})

const isModalOpen = defineModel<boolean>()

const isPasswordResetType = computed(() => type.value === CustomerAuthFormType.PASSWORD_RESET)

const { t } = useI18n()

const modalTexts = computed<{
    heading: string
}>(() => {
    switch (type.value) {
        case CustomerAuthFormType.LOGIN:
            return {
                heading: t('labels.log_in_long'),
            }
        case CustomerAuthFormType.REGISTRATION:
            return {
                heading: t('labels.registration'),
            }
        case CustomerAuthFormType.PASSWORD_RESET:
            return {
                heading: t('auth.forgotten_password'),
            }
    }
})

function handleAuthFormSubmit() {
    isModalOpen.value = false
}

</script>

<style lang="scss" scoped>

</style>
